// ** Base changes
svg:focus{
   outline: none;
}

// ** Bootstrap changes
.form-control{
   padding: 0.75rem 1rem;
   transition: all 100ms ease-in-out;

   &[type=file]{
      padding-left: 1.2rem;
   }
}
.invalid-feedback{
   font-size: 1rem;
   font-weight: 500;
}
.form-label{
   font-size: 1rem;
   margin-bottom: 0.5rem;
}

.btn.btn-line-form {
   width: 42px;
   line-height: 20px;
}

.input-group{
   .input-group-text{
      &.input-group-skip-focus{
         border-left: 0;
      }
   }
}

.table{
   &.condensed > :not(caption) > * > * {
      padding: 0.5rem;
   }   
}

.table thead th, .table tfoot th {   
   font-size: 0.8rem;
      
   > .table-header-title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;   
   }   
}

.dropdown-menu{
   button.dropdown-item{
      width: 100%;
   }
}

.dark-layout input:-webkit-autofill,
.dark-layout textarea:-webkit-autofill,
.dark-layout select:-webkit-autofill{
   -webkit-box-shadow: 0 0 0 1000px #1e253a inset !important;
}

.dark-layout input.form-control:not(:focus),
.dark-layout select.form-select:not(:focus),
.dark-layout textarea.form-control:not(:focus) {
    border-color: #1c2338;
}

.dark-layout input.form-control,
.dark-layout select.form-select,
.dark-layout textarea.form-control {
   background-color: #1e253a;
}

.dark-layout .input-group .input-group-text{
   border-color: #1c2338;
   background-color: #1e253a;
}
.dark-layout .input-group input.disabled-same-bg {
   opacity: 1;   
}

.dark-layout .input-group.input-group-filled .input-group-text{
   background-color: #283046;
}

.dark-layout .select__control {
    background-color: #1e253a !important;
    border-color: #1c2338 !important;
}
.react-select.is-invalid .select__control {
    border-color: #f1416c !important;
}
.select__control{
   min-height: 43px !important;
}
.select__multi-value, .react-select__multi-value {
   margin: 0.25rem 0.50rem 0.25rem 0 !important;
}

.table-flush-spacing{
   .form-label{
      margin-bottom: 0;
   }
}

.bg-light-dark{
   background: #f8f8f8;
}

.dark-layout .bg-light-dark{
   background: #161d31;
}

.bg-light-body {
   background: rgba(0,0,0,0.1);
   color: #6e6b7b;
}

.dark-layout .bg-light-body {
   color: #b4b7bd;
}
.card {
   .card-title {
      font-size: 1.2rem;      
   }
} 
@media (min-width: 1200px) {
   h4, .h4 {
      font-size: 1.2rem;
   }
}

// ** Badge
.badge {
   &.badge-up {
      &.stand-alone {
         padding: 0;
         min-width: 1rem;
         min-height: 1rem;
      }
   }
   &.badge-remove-file{
      right: -10px !important;
      top: -10px !important;
      padding: 0.3rem !important;
   }
}

.badge-visible-hover{
   .badge {
      display: none;
   }
   &:hover{
      .badge{
         display: block;
      }
   }
}

// ** LicencePlate
.licence-plate{
   background-image: url('../images/backgrounds/licence-plate.svg');
   background-size: 102px 22px;
   width: 102px;
   height: 22px;
   padding: 2px 0px 2px 14px;
   font-weight: 600;
   font-size: 12px;
   color: #000;
   text-align: center;

   &.lg{
      background-size: 116px 25px;
      width: 116px;
      height: 25px;
      line-height: 23px;
      font-size: 1rem;
   }
}

.image-wrapper{
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-shrink: 0;
   line-height: 1;
   border-radius: 0px;
   overflow: hidden;
   border: 1px solid rgba(0,0,0,0.05);
   width: 72px;
   height: 48px;

   &.lg{
      width: 310px;
      height: 204px;
      @media only screen and (min-device-width: 320px) and (max-device-width: 875px) and (orientation: landscape) {
         width: 100%;
         height: 180px;
      }

      .image-holder{
         width: 100%;
         max-height: 204px;

         img {
            max-height: 204px;
         }
      }
   }

   .nav-pills .nav-link img{
      width: 100%;
      height: 100%;
      text-align: center;
      object-fit: cover;
      color: transparent;
      text-indent: 10000px;
   }
}

img.image-modal {
   max-width: 100%;
   max-height: 500px;
}

// ** Table

.dark-layout {
   .table {
      thead > tr {
         border-color: #343d55 !important;            
      }
      tbody > tr > td{
         border-bottom: 1px solid #343d55 !important;  
         
         .table {
            tbody tr:last-child td {
               border-bottom: 0px !important;
            }
         }   
      }      

      &.table-striped{     
         > tbody {
            > tr {
               &:nth-child(2n+1) {                  
                  td {                                   
                     --bs-table-accent-bg: darken(#283046, 2%);
                     background: darken(#283046, 2%);
                  }
               }
            }
         }                                     
      }

      &.table-hover{
         > tbody {
            > tr {
               &:hover{
                  td{
                     --bs-table-hover-bg: darken(#283046, 4%);               
                     background: darken(#283046, 4%);
                  }
               }
            }
         }
      }
   }
}

//** Table corrections
.react-dataTable{
   .rdt_Table {
      display: table;
      border-collapse: collapse;

      .rdt_TableHead {
         display: table-row-group;

         .rdt_TableHeadRow {
            display: table-row;

            .rdt_TableCol{
               display: table-cell;
               vertical-align: middle;
               padding: 0.5rem 1rem !important;
            }
         }
      }
      .rdt_TableBody{
         display: table-row-group;

         .rdt_TableRow{
            display: table-row;
            border-bottom: 1px solid rgba(0,0,0,.12);

            .rdt_TableCell{
               display: table-cell;
               vertical-align: middle;
               padding: 1rem !important;
               border-right: 1px solid rgba(0,0,0,.12);

               &:last-child{
                  border-right: 0px;
               }
            }
         }
      }
   }
   &.react-dataTable-border-bottom{
      border-radius: 0px 0px 0.428rem 0.428rem;
   }
   &.react-dataTable-no-header{
      header {
         display: none;
      }
   }

   &.react-dataTable-wSelect{
      .rdt_TableHead {
         .rdt_TableHeadRow {
            .rdt_TableCol:first-child {
               visibility: hidden;
            }
         }
      }
      .rdt_TableBody {
         .rdt_TableRow {
            .rdt_TableCell:first-child {
               width: 35px;             
               padding-right: 0px !important;  
            }
         }
      }
   }
}

.btn:first-child:active {
   border-color: transparent;
}
.btn.btn-flat-link:active {
   border-color: transparent !important;
}

.dark-layout{
   .react-dataTable{
      .rdt_TableHead {
         .rdt_TableHeadRow{
            border-color: #343d55 !important;
         }
      }
      .rdt_TableBody{
         .rdt_TableRow{
            border-bottom: 1px solid #343d55 !important;

            .rdt_TableCell{
               border-right: 1px solid #343d55 !important;

               &:last-child{
                  border-right: 0px !important;
               }
            }
            &:nth-child(2n+1){
               background-color: darken(#283046, 2%);
            }
            &:hover{
               background-color: darken(#283046, 4%);
            }
         }
      }
      .rdt_Pagination{
         border: 0px;
      }
   }
}


//** Stepper
.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label .bs-stepper-subtitle {
    font-size: 1rem !important;
}
.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label .bs-stepper-title {
    margin-bottom: 0.3rem !important;
}

// ** utils
.white-space-nowrap {
   white-space: nowrap !important;
}

// ** car details car-details-indicator
.car-details {
   position: relative;
   padding-left: 6px;

   .car-details-indicator {
      position: absolute;
      width: 1px;
      height: 100%;
      background: #3b4253;
      left: 0px;

      &.active{
         background: #47be7d;
      }
   }
}

.file-gallery-item {
   display: flex;
   flex-direction: column;
   width:100%;
   height:100%;
   padding-bottom: 1rem;

   .file-gallery-image {
      display: block;
      position: relative;
      overflow: hidden;
      padding: .5rem;
      background: rgba(0,0,0,.05);
      height: 160px;

      img {
         position: absolute;
         width: 100%;
         object-fit: contain;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
      }
   }
   .file-gallery-placeholder {
      display: flex;
      flex: 1;
      overflow: hidden;
      padding: .5rem;
      text-align: center;
      background: rgba(0,0,0,.05);
      justify-content: center;
      align-items: center;

      img {
         width: 100%;
         object-fit: contain;
      }
   }
   .file-gallery-loading {
      display: flex;
      padding: .5rem;
      background: rgba(0,0,0,.05);
      justify-content: center;
      align-items: center;
      min-height: 160px;

      .fallback-spinner {
         width: 100%;
      }
   }
   .file-gallery-date{
      background: rgba(0,0,0,.05);
      padding: .3rem 0;
      font-size: .9rem;
      text-align: center;
   }
}

.avatar-holder {
   position: relative;
   display: block;
   width: 42px;
   height: 42px;

   .avatar{
      background: transparent;

      &.avatar-improved{
         height: 42px;
         display: block;
         position: relative;
         overflow: hidden;

         img{
            position: absolute;
            max-width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
         }
      }
   }
}

// ** Wizard
.vertical-wizard {
   .bs-stepper {
      .bs-stepper-header {
         padding: 0 1.5rem 1rem;
      }
   }
}

// ** Navigation minor changes
.vertical-layout.vertical-menu-modern .main-menu .navigation > li > a svg,
.vertical-layout.vertical-menu-modern .main-menu .navigation > li > a i {
   margin-top: -3px;
}

/*
@include media-breakpoint-down(768px) {
   .car-details {
      display: none;
   }
}*/

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover, .btn-group > .btn:focus,
.btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
   z-index: auto;
}

// Nav-pills nav-link
.nav-pills .nav-link {
   padding: 1rem;
   margin-right: 0.5rem;
}
@media (min-width: 768px) {
   .nav-pills .nav-link {
      margin-right: 0rem;
   }
}

@media (min-width: 768px) {
   .w-md-auto {
      width: auto !important;
   }
}

// Flatpicker
.flatpickr-input-hidden {
   > input {
      display: none;
   }

   .flatpickr-calendar {
      width: 100%;
      //padding: 0.5rem;

      .flatpickr-current-month {
         padding-top: 5px;
      }

      .flatpickr-months .flatpickr-prev-month,
      .flatpickr-months .flatpickr-next-month {
         top: 0px;
      }
   }

}
.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
   color: inherit !important;
}

// Condensed html content
.html-condensed {
   padding-left: 1rem;

   p, ul, ol, h1, h2, h3, h4, h5{
      margin-bottom: 0.5rem;
   }
}

// Image cropping
.crop-section {
   position: relative;
   width: 100%;

   .crop-container {
      position: relative;
      width: 100%;
      height: 200px;
      background: transparent;

      @media only screen and (min-width: 768px) {
         height: 400px;
      }
   }

   .crop-controls{
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;

      > button {
         display: block
      }
   }
}

// Dropzone
.dropzone{
   min-height: 150px !important;
}

// Other
.cursor-default {
   cursor: default !important;
}
.w-30 {
   width: 30% !important;
}
.cut-filename { 
   display: block;
   text-overflow: ellipsis;
   overflow: hidden; 
   max-width: 250px; 
   height: 1.2em;
   white-space: nowrap;
 }

.no-selection {
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

.main-menu ul.navigation-main {
   padding-bottom: 3rem;
}

.text-truncate-report {
   width: 150px;
   overflow: hidden; 
   white-space: nowrap; 
   text-overflow: ellipsis;   
}
.recharts-wrapper {
   height: 350px;
}